<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>项目名称：</p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 placeholder="项目名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>编号：</p>
          <Input v-model="number"
                 maxlength="30"
                 v-stringLimit
                 placeholder="编号"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '项目'
    },
    visible: Boolean,
    dataId: String,
    typeId: String,
  },
  data () {
    return {
      loading: false,
      name: '',
      number: '',
      status: true,
      remark: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('geCostDictItem', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.status = res.status == 0 ? true : false
      })
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入类型名称');
      let params = {
        dictId: this.typeId,
        name: this.name,
        number: this.number,
        status: this.status ? 0 : 1,
        remark: this.remark,
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditCostDictItem', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.$emit('update:typeId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.number = ''
      this.status = true
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>