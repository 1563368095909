<template>
  <Modal :value="visible"
         :title="data.dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>类型名称：</p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 placeholder="类型名称"></Input>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '类型'
    },
    visible: Boolean,
    data: Object,
  },
  data () {
    return {
      loading: false,
      name: '',
    }
  },
  watch: {
    data (newValue) {
      if(newValue.name){
        this.name = newValue.name
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 确定按钮
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入类型名称');
      let params = {
        menuId: this.data.menuId,
        costType: this.name,
      }
      this.loading = true
      if (this.data.dataId) params.id = this.data.dataId
      this.$store.dispatch('createOrEditCostDicType', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:data', {})
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>