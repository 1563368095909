<template>
  <div class="main">
    <div class="left">
      <ul class="menu">
        <li class="menu-item"
            v-for="(item,index) in parentMenuArr"
            :key="index">
          <div class="menu-title">{{item.name}}</div>
          <ul class="menu-item-list">
            <li class="menu-item-item"
                :class="{active:currentParentMenuId===items.id}"
                v-for="(items,indexs) in item.children"
                :key="indexs"
                @click.stop="onClickParentMenuItem(items,indexs,index)">
              <span>{{items.costtype}}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="left">
      <ul class="menu">
        <li class="menu-item">
          <div class="flex j-between a-center">
            <div class="menu-title">类型</div>
            <Button type="text"
                    v-hasPermi="['/admin/costDictionaries/add']"
                    @click.stop="onClickCreatedType">添加</Button>
          </div>
          <ul class="menu-item-list">
            <li class="menu-item-item"
                :class="{active:currentChildMenuId===item.id}"
                v-for="(item,index) in childMenuArr"
                :key="index"
                @click.stop="onClickChildMenuItem(item.id)">
              <span class="line1"
                    :title="item.costtype">{{item.costtype}}</span>
              <div class="flex a-center">
                <Icon custom="i-icon icon-xiugai"
                      size="16"
                      class="menu-item-icon"
                      @click.stop="onClickEditType(item)"></Icon>
                <Icon custom="i-icon icon-shanchu1"
                      size="16"
                      class="menu-item-icon"
                      @click.stop="onClickDeleteType(item)"></Icon>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <div class="content-title">
          <Icon custom="i-icon icon-leixing"
                size="16"
                color="#25bb96"
                class="m-r-8"></Icon>
          <span>项目</span>
        </div>
        <Button type="text"
                v-hasPermi="['/admin/costDictionaries/add']"
                @click.stop="onClickCreatedPart">添加</Button>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <!-- <template v-if="currentParentMenuIndex == 0">
              <template v-if="currentParentMenuItemIndex == 0">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditExamine(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 1">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditInsurance(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 2">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditMaintain(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 3">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditOther(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 4">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditRepair(row)">修改</Button>
              </template>
            </template>
            <template v-if="currentParentMenuIndex == 1">
              <template v-if="currentParentMenuItemIndex == 0">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditPersonOther(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 1">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditPersonWage(row)">修改</Button>
              </template>
            </template>
            <template v-if="currentParentMenuIndex == 2">
              <template v-if="currentParentMenuItemIndex == 0">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditOperationElectricity(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 1">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditOperationWater(row)">修改</Button>
              </template>
              <template v-if="currentParentMenuItemIndex == 2">
                <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickEditOperationOther(row)">修改</Button>
              </template>
            </template> -->
            <Button type="text"
                    class="tableOperaBtn"
                    v-hasPermi="['/admin/costDictionaries/edit']"
                    @click.stop="onClickEditItem(row)">修改</Button>
            <Button type="text"
                    class="tableOperaBtn"
                    v-hasPermi="['/admin/costDictionaries/edit']"
                    @click.stop="onClickStatusItem(row)">{{row.status == 0 ? '禁用' : '启用'}}</Button>
            <Button type="text"
                    class="tableOperaBtn"
                    v-hasPermi="['/admin/costDictionaries/delete']"
                    @click.stop="onClickDeleteItem(row)">删除</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 新增类型 -->
    <CostDictionariesTypeModal v-model="costDictionariesTypeVisible"
                               :data.sync="costDictionariesTypeData"
                               @onClickConfirm="getItemList"
                               @onChange='onChangClearRowStyle'></CostDictionariesTypeModal>

    <!-- 新增部位 -->
    <CostDictionariesPartModal v-model="costDictionariesPartVisible"
                               :dataId.sync="costDictionariesPartId"
                               :typeId.sync="costDictionariesPartTypeId"
                               @onClickConfirm="getTableList"
                               @onChange='onChangClearRowStyle'></CostDictionariesPartModal>
  </div>
</template>

<script>
import CostDictionariesTypeModal from '@/components/product/admin/modal/cost/CostDictionariesTypeModal'
import CostDictionariesPartModal from '@/components/product/admin/modal/cost/CostDictionariesPartModal'
export default {
  components: {
    CostDictionariesTypeModal,
    CostDictionariesPartModal
  },
  data () {
    return {
      currentParentMenuIndex: 0,
      currentParentMenuItemIndex: 0,
      currentParentMenuId: 0,
      parentMenuArr: [
        {
          name: '车辆成本',
          children: []
        },
        {
          name: '人员成本',
          children: []
        },
        {
          name: '运营成本',
          children: []
        },
      ],
      currentChildMenuId: 0,
      childMenuArr: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      // 年检费
      columnsExamine: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '年检项目',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '年检编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 保险费
      columnsInsurance: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '保险项目',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保险编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 油费
      columnsOil: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '油费类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '油费编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 保养费
      columnsMaintain: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '保养项目',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保养编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 车杂费
      columnsOther: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 维修费
      columnsRepair: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '维修部位',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '维修编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      // 违章费
      columnsViolation: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '违章类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违章编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsPersonOther: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '工资类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '工资编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsPersonWage: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsOperationElectricity: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '电费类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '电费编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsOperationWater: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '水费类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '水费编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsOperationOther: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '杂费类型',
          key: 'costname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '杂费编号',
          key: 'code',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 0) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 类型
      costDictionariesTypeVisible: false,
      costDictionariesTypeData: {},
      // 部位
      costDictionariesPartVisible: false,
      costDictionariesPartTypeId: '',
      costDictionariesPartId: ''
    };
  },
  watch: {
    // questBigType (newVal) {
    //   let arr = []
    //   if (newVal) {
    //     let obj = this.questBigTypeArr.find(item => item.id === newVal)
    //     arr = obj ? obj.children : []
    //   }
    //   this.questSmallType = ''
    //   this.questSmallTypeArr = arr
    // },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.currentParentMenuIndex) {
        case 0:
          switch (this.currentParentMenuItemIndex) {
            case 0:
              // 年检费
              columns = this.columnsExamine
              break;
            case 1:
              // 保险费
              columns = this.columnsInsurance
              break;
            case 2:
              // 保养费
              columns = this.columnsMaintain
              break;
            case 3:
              // 车杂费
              columns = this.columnsOther
              break;
            case 4:
              // 维修费
              columns = this.columnsRepair
              break;
            case 5:
              // 油费
              columns = this.columnsOil
              break;
            case 6:
              // 违章费
              columns = this.columnsViolation
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentParentMenuItemIndex) {
            case 0:
              columns = this.columnsPersonOther
              break;
            case 1:
              columns = this.columnsPersonWage
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentParentMenuItemIndex) {
            case 0:
              columns = this.columnsOperationElectricity
              break;
            case 1:
              columns = this.columnsOperationWater
              break;
            case 2:
              columns = this.columnsOperationOther
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 200
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 200
      }
      this.getList()
    },
    // 点击一级菜单
    onClickParentMenuItem (items, indexs, index) {
      this.currentParentMenuIndex = index
      this.currentParentMenuItemIndex = indexs
      this.currentParentMenuId = items.id
      this.getItemList()
    },
    // 点击二级菜单
    onClickChildMenuItem (id) {
      this.currentChildMenuId = id
      this.getTableList()
    },
    // 新增二级菜单
    onClickCreatedType () {
      this.costDictionariesTypeVisible = true
      this.costDictionariesTypeData.menuId = this.currentParentMenuId
    },
    // 修改二级菜单
    onClickEditType (item) {
      this.costDictionariesTypeVisible = true
      this.costDictionariesTypeData = {
        name: item.costtype,
        menuId: item.menuid,
        dataId: item.id
      }
    },
    // 删除二级菜单
    onClickDeleteType (item) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: item.id
          }
          this.$http.deleteCostDictionaries(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getItemList()
            }
          })
        }
      })
    },
    // 新增部位
    onClickCreatedPart () {
      this.costDictionariesPartVisible = true
      this.costDictionariesPartTypeId = this.currentChildMenuId
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getTableList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getTableList()
    },
    // 获取左侧大类列表
    getList () {
      let params = {
        menuid: "0"
      }
      this.$http.getCostDictionariesList(params).then((res) => {
        res.result.forEach(item => {
          if (item.type == 0) {
            this.parentMenuArr[0].children.push(item)
          } else if (item.type == 1) {
            this.parentMenuArr[1].children.push(item)
          } else if (item.type == 2) {
            this.parentMenuArr[2].children.push(item)
          }
        });
        this.currentParentMenuId = this.parentMenuArr[0].children[0].id
        this.getItemList()
      })
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 获取中间类型列表
    getItemList () {
      let params = {
        menuid: this.currentParentMenuId
      }
      this.$http.getCostDictionariesList(params).then((res) => {
        this.childMenuArr = res.result
        this.currentChildMenuId = res.result[0] && res.result[0].id
        if (this.currentChildMenuId) {
          this.getTableList()
        } else {
          this.data = []
        }
      })
    },
    // 获取表格列表
    getTableList () {
      let params = {
        dictid: this.currentChildMenuId,
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getCostDictionariesItemList(params).then((res) => {
        this.data = res.result.detallist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改列表项
    onClickEditItem (row) {
      this.selectVisitedArr.push(row)
      this.costDictionariesPartVisible = true
      this.costDictionariesPartId = row.id
      this.costDictionariesPartTypeId = this.currentChildMenuId
    },
    // 修改列表项状态
    onClickStatusItem (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 0) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditCostDictItem', params).then(res => {
              this.getTableList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditCostDictItem', params).then(res => {
              this.getTableList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 删除列表项
    onClickDeleteItem (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteCostDictionariesItem(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getTableList()
            }
          })
          this.onChangClearRowStyle()
        },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
      })
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  display: flex;
  .left {
    width: 250px;
    height: 100%;
    padding: 12px;
    background-color: #fff;
    margin-right: 16px;
    overflow: auto;
    flex-shrink: 0;
    .menu {
      .menu-title {
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        color: #2c2c2c;
        text-align: left;
        // margin-bottom: 8px;
        padding-left: 8px;
        border-left: 2px solid #25bb96;
      }
      .menu-item-list {
        margin-bottom: 8px;
        .menu-item-item {
          height: 36px;
          padding: 0 8px;
          line-height: 36px;
          font-size: 12px;
          border-bottom: 1px dotted #e1e4e6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          &:hover {
            color: #25bb96;
            background-color: #f0f3f7;
          }
          &.active {
            color: #25bb96;
            background-color: #f0f3f7;
          }
          span {
            flex: 1;
          }
          .menu-item-icon {
            color: #25bb96;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .content {
    padding: 12px;
    background-color: #fff;
    flex: 1;
    .content-title {
      font-weight: 700;
      font-size: 14px;
      // margin-bottom: 8px;
    }
    .table {
      // margin-top: 16px;
      flex: 1;
    }
  }
}
</style>